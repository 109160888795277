import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
function Social() {
  return (
    <>
      <div className="sm:w-full py-10 m-auto mt-4">
        <div className="text-2xl text-bold mb-4 font-medium tracking-widest">
          FOLLOW US
        </div>
        <div className="flex">
          <a
            href="https://www.facebook.com/Tomedes.translation"
            target="_blank"
          >
            <span className="inline-flex font text-center mr-4  hover:bg-orange hover:text-white cursor-pointer transition-colors duration-300">
              <FontAwesomeIcon
                icon={faFacebookF}
                className="text-2xl  inline-block m-auto "
              />
            </span>
          </a>
          <a href="https://twitter.com/Tomedes" target="_blank">
            <span className="inline-flex font text-center mr-4  hover:bg-orange hover:text-white cursor-pointer transition-colors duration-300">
              {/* <FontAwesomeIcon
              icon={faTwitter}
              className="text-2xl  inline-block m-auto "
            />{" "} */}
              <svg
                className="twitter"
                fill="#000000"
                width="40"
                zoomAndPan="magnify"
                viewBox="0 0 375 374.999991"
                height="40"
              >
                <defs>
                  &lt; d="M 8.917969 7.773438 L 367.417969 7.773438 L 367.417969
                  366.273438 L 8.917969 366.273438 Z M 8.917969 7.773438 " /&gt;{" "}
                </defs>

                <g transform="translate(90,80) ">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 44 44"
                    version="1.1"
                    height="full"
                    width="full"
                  >
                    <path
                      d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </g>
              </svg>
            </span>
          </a>
          <a
            href="https://www.linkedin.com/company/tomedes.com/"
            target="_blank"
          >
            <span className="inline-flex font text-center mr-4  hover:bg-orange hover:text-white cursor-pointer transition-colors duration-300">
              <FontAwesomeIcon
                icon={faLinkedinIn}
                className="text-2xl  inline-block m-auto "
              />{" "}
            </span>
          </a>
          <a
            href="https://www.youtube.com/channel/UC9k2By4XhHEjI6usn0nJfNw"
            target="_blank"
          >
            <span className="inline-flex font text-center hover:bg-orange hover:text-white cursor-pointer transition-colors duration-300">
              <FontAwesomeIcon
                icon={faYoutube}
                className="text-2xl  inline-block m-auto"
              />{" "}
            </span>
          </a>
        </div>
      </div>
    </>
  );
}
export default Social;


import SEO from "components/seo";
import { Link } from "gatsby";
import moment from "moment";
import React from "react";
import TranslatorHubHeader from "../../TranslatorHub/TranslatorHubHeader/TranslatorHubHeader";
import Form from "../Form";
import Social from "../Social";
import OptimizedImage from "hooks/OptimizedImage";
import loadable from "@loadable/component";

const Index = (props) => {
  const Footer = loadable(() => import("components/layout/footer"));
  const FormMobile = loadable(() => import("../Form"));
  const Comments = loadable(() => import("./Comments"));
  
  const {
    pageContext: {
      data: { data },
    },
  } = props;

  return (
    <React.Fragment>
      <TranslatorHubHeader title={"Business Translation Center"} />
      <SEO
        title={data.seo_project_title || data.title_x || data.seo_h1}
        description={data.seo_project_meta_desc}
        keywords={data.seo_project_meta_keywords}
        slug={data.slug}
      />
        <div className="flex flex-col lg:flex-row w-11/12 sm:w-10/12 md:w-11/12 md:px-3 md:max-w-2xl lg:max-w-none xl:max-w-7xl  mx-auto lg:w-full xl:pl-[150px] lg:gap-20">
          <div className="col-span-2">
            <h1 className="text-[28px] pb-6 text-blackNew-600 font-semibold mt-10">
              {data.seo_h1}
            </h1>

            <div className="my-6">
              {data.slug !== "/business-center/press-seo-translation" && <>
              <span className="text-blackNew-700">by</span>{" "}
              <a
                href="https://www.linkedin.com/in/ofertirosh/"
                target="_blank"
              >
                <span className="text-lightBlue font-bold">OFER TIROSH</span>
                
              </a>{" "}
              </>}
              <i className="text-blackNew-700">
                {data.seo_project_publish_date ? moment(data.seo_project_publish_date).format("DD/MM/YYYY"):moment(data.seo_project_create_date).format("DD/MM/YYYY")}
              </i>
            </div>
            <OptimizedImage
              src={data.cover_image}
              alt=""
              className="w-full xl:w-full  object-cover h-auto lg:h-[370px] max-h-80 mb-6"
              lazyload={false}
            />
            <div
              className="mb-4 translator_hub_details"
              dangerouslySetInnerHTML={{ __html: data.seo_project_content }}
            />
          <Comments
            data={data}
          />
          </div>
          <div className="w-full ml-0 mt-10 md:mt-0 px-4 lg:pl-0">
            <div>
              <h5 className="font-bold text-newGray text-new mt-4 lg:mt-10">
                CATEGORIES
              </h5>
              <ul className="text-newGray">
                {props.pageContext.uniqueCategories.map((link, index) => (
                  <li
                    className="text-new py-2  hover:text-newLightBlue cursor-pointer"
                    key={index}
                  >
                    <Link to={`/business-center.php/category/${link}`}>{link}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="line border-b border-gray mt-12"></div>
            <div className="hidden md:block">
              <Form />
            </div>
            <div className="md:hidden">
              <FormMobile/>
            </div>
            <Social />
          </div>
        </div>
      <Footer />
    </React.Fragment>
  );
};
export default Index;
